<template>
  <div class="contact-container">
    <Header active="Contact"></Header>

    <div class="contact-content">
      <div class="top-image">
        <div class="title">Contact Us</div>
      </div>
      <div class="contact-list">
        <div class="tips">
          If you have any questions, welcome to contact us via whatsapp or
          Email.
        </div>
        <div class="contact-items">
          <img src="../../assets/images/whatsapp.png" />
          <div class="contact-type">WhatsApp:+8618179849917</div>
        </div>
        <div class="contact-items">
          <img src="../../assets/images/email.png" />
          <div class="contact-type">Email:Gcard8business@giftcard8.com</div>
        </div>
      </div>
      <el-backtop :bottom="100">
        <div
          style="
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          "
        >
          UP
        </div>
      </el-backtop>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";

export default defineComponent({
  name: "Constact",
  components: { Header, Footer },
  setup() {
    onMounted(() => {
      document.documentElement.scrollTop = 0;
    });

    return {};
  },
});
</script>

<style lang="less">
.contact-container {
  position: relative;
  background-color: rgba(247, 247, 247, 1);
  .contact-content {
    .top-image {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-image: url("../../assets/images/top_img_bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      font-size: 20px;
      .title {
        margin-bottom: 36px;
        font-size: 60px;
      }
    }
    .contact-list {
      position: relative;
      overflow: hidden;
      margin: 20px 200px;
      padding: 30px;
      text-align: left;
      font-size: 26px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 10px;
      .tips {
        margin-bottom: 30px;
        font-weight: bold;
      }
      .contact-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 26px;
          height: 26px;
        }
        .contact-type {
          flex: 1;
          margin-left: 14px;
          text-align: left;
          color: #333333;
          font-size: 26px;
        }
      }
    }
  }
}
</style>
